import { useEffect, useMemo } from "react"
import { useIntl } from "react-intl"

import historyMessages from "messages/history"
import { useMst } from "models/Root"

const useOrderHistory = ({ isShowAll = true }) => {
	const { formatMessage } = useIntl()
	const {
		global: { isAuthenticated },
		history: {
			loadClosedOrders,
			closedOrdersList,
			isClosedOrdersLoading,
			setFilterTypeOrderHistory,
			filterTypeOrderHistory,
			setCurrentOpenOrderHistoryOrderID,
			currentOpenOrderHistoryOrderID,
			unformattedTradeHistoryList,
		},
		terminal: { pairSymbol },
		bybit: {
			currentPair: { symbol },
			pairAmountPrecision,
			pairPricePrecision,
		},
	} = useMst()
	const params = useMemo(
		() => ({
			symbol: !isShowAll ? symbol : undefined,
			category: "spot",
		}),
		[symbol, isShowAll],
	)

	const listScheme = [
		{
			arrCount: 1,
			id: 12,
			arrNames: [
				{
					headline: formatMessage(historyMessages.active_orders_pair),
					width: 110,
					asociate: "name",
				},
			],
			isScroll: false,
		},
		{
			arrCount: 10,
			id: 22,
			isScroll: true,
			arrNames: [
				{
					headline: formatMessage(historyMessages.active_orders_side),
					width: 80,
					asociate: "direction",
				},
				{
					headline: "Trade Type",
					width: 100,
					asociate: "tradeType",
				},
				{
					headline: "Order Type",
					width: 80,
					asociate: "orderType",
				},
				{
					headline: formatMessage(historyMessages.active_orders_total),
					width: 168,
					asociate: "orderValue",
				},
				// {
				// 	headline: `${formatMessage(historyMessages.active_orders_price)} AVG`,
				// 	width: 168,
				// 	asociate: "avgFilledPrice",
				// },
				{
					headline: formatMessage(historyMessages.active_orders_filled),
					width: 168,
					asociate: "filledQty",
				},
				{
					headline: formatMessage(historyMessages.active_orders_price),
					width: 124,
					asociate: "orderPrice",
				},
				{ headline: "Order Qty", width: 148, asociate: "orderQty" },
				{ headline: formatMessage(historyMessages.state), width: 110, asociate: "orderStatus" },
				{ headline: "Order Time", width: 168, asociate: "orderTime" },
				{ headline: "Order ID", width: 100, asociate: "orderId" },
			],
		},
		// {
		// 	arrCount: 1,
		// 	id: 32,
		// 	isScroll: false,
		// 	arrNames: [{ headline: "Action", width: 80, asociate: "details" }],
		// },
	]

	const listHeadline = listScheme.flatMap(item => item.arrNames)

	useEffect(() => {
		if (isAuthenticated && symbol !== "") {
			loadClosedOrders(params).then(() => null)
		}
	}, [isAuthenticated, symbol, params, isShowAll])

	useEffect(() => {
		setCurrentOpenOrderHistoryOrderID("")

		return () => {
			setCurrentOpenOrderHistoryOrderID("")
		}
	}, [filterTypeOrderHistory])

	const list = useMemo(
		() =>
			closedOrdersList.map(data => ({
				...data,
				// trades: data.trades.length
				// 	? data.trades
				// 	: unformattedTradeHistoryList.filter(({ order_id }) => data.orderId === order_id),
				trades: [],
			})),
		[closedOrdersList, unformattedTradeHistoryList],
	)

	return {
		listScheme,
		listHeadline,
		list,
		isAuthenticated,
		pairAmountPrecision,
		pairPricePrecision,
		isClosedOrdersLoading,
		setFilterTypeOrderHistory,
		filterTypeOrderHistory,
		setCurrentOpenOrderHistoryOrderID,
		currentOpenOrderHistoryOrderID,
	}
}

export default useOrderHistory
