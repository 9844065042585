import React, { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import cn from "classnames"

import { EWithdrawLoading } from "types/withdrawal"
import { routes } from "constants/routing"
import { useMst } from "models/Root"
import { IWithdrawalMethod } from "models/Withdrawal"
import useWindowSize from "hooks/useWindowSize"
import useLocaleNavigate from "hooks/useLocaleNavigate"
import financeMessages from "messages/finance"
import commonMessages from "messages/common"

import Input, { Appender, AppenderButton, AppenderDivider } from "components/UI/Input"
import LoadingSpinner from "components/UI/LoadingSpinner"
import InternalLink from "components/InternalLink"
import ActionMethods from "components/ActionMethods"
import InfoSnack from "components/InfoSnack"
import Button from "components/UI/Button"

import styles from "styles/components/DepositWithdrawal.module.scss"

import HelpInfo from "./HelpInfo"
import VerificationRequired from "./VerificationRequired"

const FORMAT_NUMBER_OPTIONS = {
	maximumFractionDigits: 8,
	useGrouping: false,
}

const RequisitesStep: React.FC = () => {
	const {
		account: { profileStatus },
		withdrawal: {
			getIsLoading,
			currentCurrency,
			loadMethods,
			methods,
			setCurrentMethod,
			currentMethod,
			createWithdrawal,
		},
		global: { locale },
		render,
	} = useMst()
	const { formatNumber, formatMessage } = useIntl()
	const { mobile } = useWindowSize()
	const localeNavigate = useLocaleNavigate()

	const [address, setAddress] = useState<string>("")
	const [tag, setTag] = useState<string>("")
	const [amount, setAmount] = useState<string>("")

	useEffect(() => {
		loadMethods()
	}, [currentCurrency?.code])

	const isSubmitDisabled =
		address.length < 10 ||
		!currentCurrency ||
		!currentMethod ||
		+(amount || 0) > currentCurrency?.available ||
		+(amount || 0) < currentMethod?.min_withdraw

	const formatAmount = (value: string) => {
		const parsedValue = parseFloat(value)
		return Number.isNaN(parsedValue) ? "" : parsedValue.toFixed(8).replace(/\.?0+$/, "")
	}

	const handleAllAmountFill = (): void => {
		if (currentCurrency) {
			setAmount(formatAmount(currentCurrency.available.toString()))
		}
	}

	const handleAddressChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		setAddress(value)
	}, [])

	const handleTagChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		setTag(value)
	}, [])

	const handleAmountChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		setAmount(value)
	}, [])

	const handleAmountBlur = useCallback(() => {
		if (!currentMethod) return
		setAmount(value =>
			formatAmount(
				Math.min(
					Math.max(parseFloat(value), currentMethod.min_withdraw),
					currentCurrency?.available || parseFloat(value),
				).toString(),
			),
		)
	}, [currentMethod])

	const handleMethodChange = useCallback(
		(newMethod: IWithdrawalMethod) => setCurrentMethod(newMethod.id),
		[],
	)

	const handleSubmit = async () => {
		if (isSubmitDisabled || !currentMethod) return
		const formattedAmount = formatAmount(amount)
		const slug = await createWithdrawal({
			withdraw_method: currentMethod.id,
			address,
			amount: formattedAmount,
			tag: tag.length ? tag : undefined,
		})
		if (slug) localeNavigate(routes.confirm.getWithdrawConfirm(slug))
	}

	const handleInputKeyDown = useCallback(
		(e: React.KeyboardEvent) => {
			if (e.key === "Enter") handleSubmit()
		},
		[handleSubmit],
	)

	const getInfoSnackLink = useCallback(() => {
		if (currentCurrency) {
			const { code } = currentCurrency
			if (code === "ALC") {
				return `/${locale}/social-listing`
			}
		}
		return ""
	}, [locale, currentCurrency])

	const getInfoSnackColor = useCallback((): "yellow" | "red" => {
		if (currentCurrency) {
			const { name, code } = currentCurrency
			if (name.toUpperCase().split(" ").includes("DEMO") || code === "ALC") {
				return "yellow"
			}
		}
		return "red"
	}, [currentCurrency])

	const getInfoSnackText = useCallback((): string => {
		if (currentCurrency) {
			const { name, code } = currentCurrency
			if (name.toUpperCase().split(" ").includes("DEMO")) {
				return formatMessage(financeMessages.withdraw_is_impossible_demo)
			}
			if (code === "ALC") {
				return formatMessage(financeMessages.withdraw_is_impossible_alc)
			}
		}
		return formatMessage(financeMessages.withdrawal_is_not_available)
	}, [currentCurrency])

	const isEnoughVerificationLevel =
		(profileStatus?.verification_level ?? 0) >= (currentMethod?.min_verification_level ?? 0)

	useEffect(() => {
		setAddress("")
		setTag("")
		setAmount("")
	}, [currentCurrency?.code, currentMethod?.id])

	return (
		<div className={styles.step_container}>
			<div className={styles.step_info}>
				<span className={styles.step_info_title}>
					2.&nbsp;{formatMessage(financeMessages.enter_recipient_details)}
				</span>
			</div>
			{getIsLoading(EWithdrawLoading.METHODS) ? (
				<LoadingSpinner align="top" />
			) : methods?.length > 0 && methods.some(m => m.is_withdraw_enabled) ? (
				<>
					<div className={styles.form_requisites_container}>
						<ActionMethods
							title={formatMessage(financeMessages.withdrawal_method)}
							methods={methods}
							currentMethod={currentMethod as IWithdrawalMethod}
							type="withdraw"
							onChange={handleMethodChange}
						/>
					</div>
					{!!currentMethod &&
						(isEnoughVerificationLevel ? (
							<>
								<div className={styles.form_requisites_container}>
									<div className={styles.form_input_group}>
										<Input
											onChange={handleAddressChange}
											value={address}
											labelValue={`${formatMessage(commonMessages.address)}*`}
											appender={<Appender>{currentCurrency?.code?.toUpperCase() ?? ""}</Appender>}
										/>
									</div>
									<div className={styles.form_input_group}>
										<Input
											onChange={handleTagChange}
											value={tag}
											labelValue={`Tag (${formatMessage(commonMessages.optional)})`}
											maxLength={255}
										/>
									</div>
									<div className={styles.form_input_group}>
										<Input
											name="amount_input"
											type="number"
											onChange={handleAmountChange}
											onBlur={handleAmountBlur}
											value={amount}
											labelValue={`${formatMessage(commonMessages.quantity)}*`}
											appender={
												<Appender>
													{currentCurrency?.code?.toUpperCase() ?? ""}
													<AppenderDivider />
													<AppenderButton onClick={handleAllAmountFill}>all</AppenderButton>
												</Appender>
											}
											helpText={`${formatMessage(financeMessages.withdraw_min_sum)} ${
												formatNumber(
													parseFloat(
														currentMethod?.min_withdraw?.toString().replace(",", ".") ?? "0",
													),
													FORMAT_NUMBER_OPTIONS,
												) ?? "0"
											} ${currentCurrency?.code?.toUpperCase() ?? ""}`}
											onKeyDown={handleInputKeyDown}
										/>
									</div>
								</div>
								<div className={styles.form_action_container}>
									<div className={styles.submit_button_container}>
										<Button
											fullWidth
											variant="filled"
											color="primary"
											isLoading={getIsLoading(EWithdrawLoading.CREATING)}
											disabled={isSubmitDisabled}
											onClick={handleSubmit}
											iconAlign="left"
											iconCode="mini_up_right"
											label={formatMessage(financeMessages.withdraw_action)}
										/>
									</div>
									<div className={styles.terms_of_use}>
										<span>
											By making Withdraw you agree with the&nbsp;
											<InternalLink to={routes.termsOfUse}>Terms of Use</InternalLink>
										</span>
									</div>
								</div>
							</>
						) : (
							<div className={cn(styles.form_action_container, styles.with_padding)}>
								<VerificationRequired level={currentMethod?.min_verification_level ?? 0} />
							</div>
						))}
					{mobile && render.supportCenter && <HelpInfo />}
				</>
			) : (
				<InfoSnack color={getInfoSnackColor()} link={getInfoSnackLink()} iconCode="warning">
					<span>{getInfoSnackText()}</span>
				</InfoSnack>
			)}
		</div>
	)
}

export default observer(RequisitesStep)
