/* eslint-disable guard-for-in */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import dayjs from "dayjs"
import { toast } from "react-toastify"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import PulseLoader from "react-spinners/PulseLoader"
import { useParams } from "react-router-dom"

import { queryVars } from "constants/query"
import { useMst } from "models/Root"
import useLocalStorage from "hooks/useLocalStorage"
import messages from "messages/common"
import historyMessages from "messages/history"
import { IHistoryOrder } from "models/History"
import ExchangeService from "services/ExchangeService"
import {
	widget as Widget,
	ChartingLibraryWidgetOptions,
	LanguageCode,
	TimeFrameItem,
	ResolutionString,
	IChartingLibraryWidget,
	IBasicDataFeed,
	LibrarySymbolInfo,
	SubscribeBarsCallback,
} from "charting_library"
import { ACCOUNT_TYPE } from "constants/exchange"
import useAccountType from "hooks/useAccountType"
import { ChartEventEnum, ChartThemesEnum } from "types/exchange"
import { formatNumberNoRounding } from "utils/format"
import { IRecentTrade } from "models/Terminal/recentTrades"
import { OrderSideEnum, OrderTypeEnum } from "types/orders"
import {
	TERMINAL_CHART_INTERVAL_CACHE_KEY,
	TERMINAL_CHART_UC_CACHE_KEY,
	TV_DEFAULT_STUDIES_CACHE_KEY,
} from "utils/cacheKeys"
import config from "helpers/config"
import cache from "helpers/cache"
import QuickOrderPanel from "components/QuickOrder/QuickOrderPanel"
import styles from "./index.module.scss"
import {
	availableLocales,
	disabledFeatures,
	enabledFeatures,
	getLoadingScreen,
	getOverrides,
	getStudiesOverrides,
	historyDepth,
	supportedResolutions,
	SupportedResolutionsEnum,
} from "./options"
import BybitService from "../../../../services/BybitService"
import useBybitWebSocket from "../../../../hooks/newUI/useBybitWebSocket"

const containerId = "tv_chart_container"

const MAX_ERRORS_COUNT = 10

interface ISubscriber {
	symbol: string
	resolution: ResolutionString
	callback: SubscribeBarsCallback
	resetCache: () => void
}

// just copied from old design
const Chart: React.FC = () => {
	const {
		global: { theme, locale },
		bybit: {
			currentPair: { baseCoin, quoteCoin, symbol: pairParams },
			pairPricePrecision,
			pairAmountPrecision,
			chartCompareSymbols,
		},
		terminal: {
			recentTrades,
			displayChartOrders,
			setChartSubscribeSymbol,
			isQuickOrderPlacementOpen,
		},
		history: { openedOrders },
	} = useMst()
	const [cachedInterval, setCachedInterval] = useLocalStorage(
		TERMINAL_CHART_INTERVAL_CACHE_KEY,
		"15",
	)
	const [cachedUC, setCachedUC] = useLocalStorage(TERMINAL_CHART_UC_CACHE_KEY, "")
	const terminalType = useAccountType()
	const { formatMessage } = useIntl()
	const subscribers = useRef<Record<string, ISubscriber>>({})
	const recentBars = useRef<Record<string, any>>({})
	const showOrdersBtn = useRef<any>(null)
	const displayOrders = useRef<any[]>([])
	const errorsCount = useRef<number>(0)
	const tvWidget = useRef<IChartingLibraryWidget | null>(null)
	const [hasError, setHasError] = useState<boolean>(false)
	const [currentResolution, setCurrentResolution] = useState<string>("")
	const [quickOrderPanelDragging, setQuickOrderPanelDragging] = useState<boolean>(false)
	const [isChartReady, setIsChartReady] = useState<boolean>(false)
	const [isThemeReady, setIsThemeReady] = useState<boolean>(false)
	const pricePrecision = useMemo(() => pairPricePrecision ?? 8, [pairPricePrecision])
	const amountPrecision = useMemo(() => pairAmountPrecision ?? 8, [pairAmountPrecision])

	const buildOptions = (
		datafeed: IBasicDataFeed,
		interval: ResolutionString,
	): ChartingLibraryWidgetOptions => ({
		symbol: pairParams ?? "",
		datafeed: datafeed,
		interval: interval,
		container: containerId,
		library_path: "/s/tv/charting_library/",
		custom_css_url: `/s/tv/charting_library/custom-styles.css?v8`,
		locale: (availableLocales.includes(locale as LanguageCode) ? locale : "en") as LanguageCode,
		fullscreen: false,
		autosize: true,
		toolbar_bg: "#101014",
		loading_screen: getLoadingScreen("dark"),
		timeframe: historyDepth[interval].timeFrame.toString(),
		width: 100,
		theme: "dark",
		height: 400,
		timezone: "exchange",
		load_last_chart: true,
		drawings_access: { type: "black", tools: [{ name: "Regression Trend" }] },
		disabled_features: disabledFeatures,
		enabled_features: enabledFeatures,
		time_scale: {
			min_bar_spacing: 2,
		},
		overrides: getOverrides("dark"),
		studies_overrides: getStudiesOverrides("dark"),
	})

	const addSubscriber = (
		symbolInfo: LibrarySymbolInfo,
		subscriberUID: string,
		resolution: ResolutionString,
		onRealtimeCallback: SubscribeBarsCallback,
		onResetCacheNeededCallback: () => void,
	) => {
		if (!subscribers.current[subscriberUID]) {
			subscribers.current[subscriberUID] = {
				symbol: symbolInfo.ticker ?? "",
				resolution: resolution,
				callback: onRealtimeCallback,
				resetCache: onResetCacheNeededCallback,
			}
		}
	}

	const hideOrdersFromChart = () => {
		displayOrders.current.forEach(order => {
			const delFunc = order.remove.bind(order)
			delFunc()
		})
		displayOrders.current = []
	}

	const delSubscriber = (subscriberUID: any) => {
		delete subscribers.current[subscriberUID]
	}

	const buildDataFeed = (): IBasicDataFeed => ({
		onReady: function (callback: any) {
			setTimeout(() => {
				callback({
					supports_search: false,
					supports_group_request: false,
					supports_marks: false,
					supported_resolutions: supportedResolutions,
				})
			}, 0)
		},
		resolveSymbol: function (
			symbolName: string,
			onSymbolResolvedCallback: any,
			onResolveErrorCallback: any,
		) {
			setTimeout(() => {
				if (symbolName) {
					onSymbolResolvedCallback({
						name: symbolName,
						ticker: symbolName,
						description: "",
						pricescale: 10 ** (pricePrecision ?? 0),
						minmov: 1,
						timezone: "Etc/UTC",
						session: "24x7",
						has_intraday: true,
						volume_precision: amountPrecision ?? 0,
						exchange: config.department ?? "",
						has_empty_bars: true,
						// full_name: ticker.label,
						type: "exchange",
						listed_exchange: config.department ?? "",
						format: "price",
						supported_resolutions: supportedResolutions as ResolutionString[],
					})
				} else {
					onResolveErrorCallback()
				}
			}, 0)
		},
		getBars: function (
			symbolInfo: any,
			resolution: SupportedResolutionsEnum | string,
			periodParams: { from: number; to: number; firstDataRequest: boolean },
			onHistoryCallback: any,
			onErrorCallback: any,
		) {
			const timeFrom = new Date(periodParams.from * 1000)
			const timeTo = new Date(periodParams.to * 1000)
			const params: any = { start: timeFrom.getTime(), end: timeTo.getTime() }

			setCurrentResolution(resolution)

			if (symbolInfo && errorsCount.current <= MAX_ERRORS_COUNT) {
				BybitService.getChartData({
					symbol: symbolInfo.name,
					category: "spot",
					interval:
						SupportedResolutionsEnum[resolution as keyof typeof SupportedResolutionsEnum] ??
						resolution,
					limit: 500,
					...params,
				})
					.then(data => {
						const bars =
							data.list.length > 0
								? data.list.map((bar: string[]) => ({
										close: bar[4],
										high: bar[2],
										low: bar[3],
										open: bar[1],
										time: Number(bar[0]),
										volume: bar[6],
								  }))
								: []

						setCachedInterval(resolution)

						if (bars.length !== 0) {
							onHistoryCallback(bars.reverse(), { noData: false })
						} else {
							onHistoryCallback([], { noData: true })
						}
					})
					.catch(() => {
						errorsCount.current += 1

						if (errorsCount.current > MAX_ERRORS_COUNT) {
							setHasError(true)
						}

						if (typeof onErrorCallback === "function") {
							onErrorCallback()
						}
					})
				//
				// ExchangeService.getChartData(symbolInfo.ticker, resolution, params)
				// 	.then(bars => {
				// 		console.log(bars)
				// 		// if (firstDataRequest && bars.length) {
				// 		// 	recentBars.current[`${symbolInfo.ticker}_${resolution}`] = bars[0]
				// 		// }
				// 		//
				// 		// setCachedInterval(resolution)
				// 		//
				// 		// if (bars.length) {
				// 		// 	bars.reverse().forEach((bar: any) => {
				// 		// 		bar.time *= 1000
				// 		// 	})
				// 		// 	onHistoryCallback(bars)
				// 		// } else {
				// 		// 	onHistoryCallback(!Array.isArray(bars) ? [] : bars, { noData: true })
				// 		// }
				// 	})
				// 	.catch(() => {
				// 		errorsCount.current += 1
				//
				// 		if (errorsCount.current > MAX_ERRORS_COUNT) {
				// 			setHasError(true)
				// 		}
				//
				// 		if (typeof onErrorCallback === "function") {
				// 			onErrorCallback()
				// 		}
				// 	})
			}
		},
		subscribeBars: function (
			symbolInfo: LibrarySymbolInfo,
			resolution: ResolutionString,
			onRealtimeCallback: SubscribeBarsCallback,
			subscriberUID: string,
			onResetCacheNeededCallback: () => void,
		) {
			setChartSubscribeSymbol(symbolInfo.ticker ?? "")
			addSubscriber(
				symbolInfo,
				subscriberUID,
				resolution,
				onRealtimeCallback,
				onResetCacheNeededCallback,
			)
		},
		unsubscribeBars: function (subscriberUID: any) {
			delSubscriber(subscriberUID)
		},
		searchSymbols: function (
			userInput: string,
			exchange: string,
			symbolType: string,
			onResultReadyCallback: any,
		) {
			const results = chartCompareSymbols.filter(s =>
				s.symbol.toLowerCase().includes(userInput.toLowerCase()),
			)
			onResultReadyCallback(results)
		},
	})

	const saveChart = () => {
		if (tvWidget.current) {
			tvWidget.current.save((data: any) => {
				delete data.charts[0].chartProperties
				setCachedUC(data)
			})
		}
	}

	const handleCancelClick = (id: string) => {
		ExchangeService.cancelOrder(id).then(() => {
			toast(
				<>
					<i className="ai ai-check_outline" />
					{formatMessage(historyMessages.order_was_cancelled)}
				</>,
			)
		})
	}

	const createOrderLine = (
		price: number,
		type: OrderTypeEnum,
		amountUnfilled: number,
		stopPrice: number,
		stopOperator: number,
		color: string,
		id: string,
	) => {
		if (!pairParams || !tvWidget.current) {
			return null
		}
		const nextPrice = +formatNumberNoRounding(price, pairPricePrecision)
		const nextAmountUnfilled = +formatNumberNoRounding(amountUnfilled, pairAmountPrecision)

		return tvWidget.current
			.chart()
			.createPositionLine({})
			.setLineLength(100)
			.onClose("onCancel called", () => {
				handleCancelClick(id.toString())
			})
			.setText(
				type === OrderTypeEnum.STOP_LIMIT
					? `Stop Limit: ${nextAmountUnfilled} ${baseCoin ?? ""}. Trigger ${
							stopOperator === 1 ? ">=" : "<="
					  } ${stopPrice}`
					: `Limit: ${nextAmountUnfilled} ${baseCoin ?? ""}`,
			)
			.setQuantity(nextPrice.toString())
			.setPrice(nextPrice)
			.setBodyBorderColor(color)
			.setLineColor(color)
			.setBodyTextColor("#ffffff")
			.setBodyBackgroundColor("#000")
			.setBodyFont("normal 10px Verdana")
			.setQuantityBackgroundColor(color)
			.setQuantityBorderColor(color)
			.setCloseButtonBorderColor(color)
			.setCloseButtonBackgroundColor(color)
			.setCloseButtonIconColor("#fff")
	}

	const displayOrdersOnChart = (openedOrders: IHistoryOrder[]) => {
		displayOrders.current.forEach(order => {
			const delFunc = order.remove.bind(order)
			delFunc()
		})
		displayOrders.current = []

		openedOrders.forEach((order: IHistoryOrder) => {
			const orderLine = createOrderLine(
				order.price ?? 0,
				order.type as OrderTypeEnum,
				order.amount_unfilled ?? 0,
				order.stop_price ?? 0,
				order.stop_operator ?? 0,
				order.side === OrderSideEnum.SELL ? "#ff5f66" : "#21c177",
				order.id,
			)
			if (orderLine) {
				displayOrders.current.push(orderLine)
			}
		})
	}

	const updateChart = (type: string, data: Record<string, any> = {}) => {
		if (isChartReady && tvWidget.current) {
			switch (type) {
				case ChartEventEnum.SYMBOL: {
					tvWidget.current.chart().setResolution(cachedInterval, () => null)
					tvWidget.current.chart().setSymbol(data.symbol, () => null)
					saveChart()
					break
				}
				case ChartEventEnum.CHANGE_THENE: {
					setIsThemeReady(false)
					tvWidget.current.changeTheme("dark").then(() => {
						// @ts-ignore
						tvWidget.current?.applyOverrides(getOverrides("dark"))
						tvWidget.current?.applyStudiesOverrides(getStudiesOverrides("dark"))

						setTimeout(() => {
							setIsThemeReady(true)
						}, 200)
					})
					break
				}
				case ChartEventEnum.ADD_DEFAULT_STUDIES: {
					cache.setItem(TV_DEFAULT_STUDIES_CACHE_KEY, [])

					if (tvWidget.current) {
						tvWidget.current?.activeChart().removeAllStudies()

						// tvWidget.current
						// 	?.activeChart()
						// 	.createStudy(
						// 		"Moving Average",
						// 		false,
						// 		false,
						// 		{ length: 10 },
						// 		{ showLabelsOnPriceScale: false, "plot.color": "#798cf2" },
						// 	)
						// 	.then(id => {
						// 		updateDefaultStudiesCache(id)
						// 	})
						//
						// tvWidget.current
						// 	?.activeChart()
						// 	.createStudy(
						// 		"Moving Average",
						// 		false,
						// 		false,
						// 		{ length: 30 },
						// 		{ showLabelsOnPriceScale: false, "plot.color": "#d582d4" },
						// 	)
						// 	.then(id => {
						// 		updateDefaultStudiesCache(id)
						// 	})
						//
						// tvWidget.current
						// 	?.activeChart()
						// 	.createStudy(
						// 		"Moving Average",
						// 		false,
						// 		false,
						// 		{ length: 60 },
						// 		{ showLabelsOnPriceScale: false, "plot.color": "#ded373" },
						// 	)
						// 	.then(id => {
						// 		updateDefaultStudiesCache(id)
						// 	})

						// tvWidget.current?.activeChart().createStudy(
						// 	"Volume",
						// 	false,
						// 	false,
						// 	{},
						// 	{
						// 		"volume.color.0": "#ff5f66",
						// 		"volume.color.1": "#21c177",
						// 	},
						// )
					}

					break
				}
				case ChartEventEnum.ADD_TRADE: {
					updateBars(data.trade)
					break
				}
				case ChartEventEnum.HIDE_ORDERS: {
					hideOrdersFromChart()
					break
				}
				case ChartEventEnum.DISPLAY_ORDERS: {
					displayOrdersOnChart(data.orders)
					break
				}
				default:
					break
			}
		}
	}

	useEffect(() => {
		if (!tvWidget.current && chartCompareSymbols.length && pairParams && baseCoin && quoteCoin) {
			const options = buildOptions(buildDataFeed(), cachedInterval)

			tvWidget.current = new Widget(options)
			tvWidget.current.onChartReady(() => {
				setIsChartReady(true)
				tvWidget.current?.headerReady().then(() => {
					if (tvWidget.current) {
						const button = tvWidget.current.createButton()
						if (button) {
							button.setAttribute(
								"title",
								displayChartOrders
									? formatMessage(messages.not_display_chart_orders)
									: formatMessage(messages.display_chart_orders),
							)
							// button.addEventListener("click", toggleDisplayChartOrders)
							button.textContent = displayChartOrders
								? formatMessage(messages.not_display_chart_orders)
								: formatMessage(messages.display_chart_orders)
						}
						showOrdersBtn.current = button
						tvWidget.current.load(cachedUC)
					}
				})
			})
		}
	}, [pairParams, chartCompareSymbols, pairPricePrecision, pairAmountPrecision])

	useEffect(() => {
		if (isChartReady) {
			updateChart(ChartEventEnum.ADD_DEFAULT_STUDIES)
			updateChart(ChartEventEnum.CHANGE_THENE, { theme: "dark" })
		}
	}, [theme, isChartReady])

	useEffect(() => {
		if (displayChartOrders && pairParams && baseCoin && quoteCoin) {
			// const nextActiveOrders =
			// 	openedOrders.filter(
			// 		order =>
			// 			order.symbol === pairParams &&
			// 			(terminalType ? order.wallet_type === (ACCOUNT_TYPE[terminalType] ?? 1) : true),
			// 	) ?? []
			// updateChart(ChartEventEnum.DISPLAY_ORDERS, { orders: nextActiveOrders })
		} else {
			updateChart(ChartEventEnum.HIDE_ORDERS)
		}
	}, [displayChartOrders, pairParams, openedOrders.length, terminalType])

	useEffect(() => {
		if (isChartReady && pairParams && baseCoin && quoteCoin) {
			// setTimeout(() => {
			// 	const nextActiveOrders =
			// 		openedOrders.filter(
			// 			order =>
			// 				order.symbol === pairParams &&
			// 				(terminalType ? order.wallet_type === (ACCOUNT_TYPE[terminalType] ?? 1) : true),
			// 		) ?? []
			// 	updateChart(ChartEventEnum.DISPLAY_ORDERS, { orders: nextActiveOrders })
			// }, 500) // delay for fully chart initialization
		}
	}, [isChartReady, pairParams, openedOrders.length, terminalType])

	useEffect(() => {
		if (showOrdersBtn.current) {
			showOrdersBtn.current.setAttribute(
				"title",
				displayChartOrders
					? formatMessage(messages.not_display_chart_orders)
					: formatMessage(messages.display_chart_orders),
			)
			showOrdersBtn.current.textContent = displayChartOrders
				? formatMessage(messages.not_display_chart_orders)
				: formatMessage(messages.display_chart_orders)
		}
	}, [showOrdersBtn.current, displayChartOrders])

	useEffect(() => {
		if (isChartReady && pairParams && baseCoin && quoteCoin) {
			for (const subscriber of Object.values(subscribers.current)) {
				subscriber.resetCache()
			}
			setTimeout(() => {
				updateChart(ChartEventEnum.SYMBOL, { symbol: pairParams })
			})
		}
	}, [isChartReady, pairParams])

	useEffect(() => {
		if (isChartReady && recentTrades[0] && baseCoin && quoteCoin) {
			updateChart(ChartEventEnum.ADD_TRADE, {
				trade: recentTrades[0],
			})
		}
	}, [isChartReady, recentTrades[0]])

	useEffect(() => {
		window.onbeforeunload = () => saveChart()
	}, [])

	const { klineData } = useBybitWebSocket({
		baseUrl: "wss://stream.bybit.com/v5/public/spot",
		channels: [`kline.${currentResolution}.${pairParams}`],
		disabled: !currentResolution || !pairParams,
	})

	useEffect(() => {
		if (klineData) {
			for (const i in subscribers.current) {
				const subscriber = subscribers.current[i]

				subscriber.callback({
					time: klineData.timestamp,
					open: klineData.open,
					high: klineData.high,
					low: klineData.low,
					close: klineData.close,
					volume: klineData.volume,
				})
			}
		}
	}, [klineData])

	const updateBars = (trade: IRecentTrade) => {
		if (!trade) {
			return
		}

		for (const i in subscribers.current) {
			const subscriber = subscribers.current[i]
			if (trade.symbol === subscriber.symbol) {
				const open = trade.price
				const barKey = `${subscriber.symbol}_${subscriber.resolution}`
				const barData = {
					resolution: subscriber.resolution,
					bar: recentBars.current[barKey],
					time: trade.date,
					open: open,
					close: trade.price,
					high: open,
					low: open,
					volume: trade.amount,
				}

				const price = trade.price

				if (price > barData.high) barData.high = price
				else if (price < barData.low) barData.low = price

				const recentBar = createBar(barData)
				subscriber.callback(recentBar)
				recentBars.current[barKey] = recentBar
			}
		}
	}

	const createBar = (data: any) => {
		const utc = dayjs(data.time * 1000).utc()
		let timestamp = parseInt(utc.format("X"), 10)

		if (data.resolution === "1D" || data.resolution === "D") {
			timestamp -=
				parseInt(utc.format("H"), 10) * 3600 +
				parseInt(utc.format("m"), 10) * 60 +
				parseInt(utc.format("s"), 10)
		} else {
			timestamp -=
				(parseInt(utc.format("m"), 10) % parseInt(data.resolution, 10)) * 60 +
				parseInt(utc.format("s"), 10)
		}

		timestamp *= 1000
		// if no last bar or timestams not equals, then build new bar
		const isNew = !data.bar || parseInt(data.bar.time, 10) !== timestamp

		return {
			time: timestamp,
			open: isNew ? data.open : data.bar.open,
			close: data.close,
			high: isNew ? data.high : Math.max(data.bar.high, data.high),
			low: isNew ? data.low : Math.min(data.bar.low, data.low),
			volume: isNew ? data.volume : data.volume + data.bar.volume,
		}
	}

	const onStartDrag = () => {
		setQuickOrderPanelDragging(true)
	}

	const onStopDrag = () => {
		setQuickOrderPanelDragging(false)
	}

	return (
		<div className={styles.chart}>
			{!isThemeReady ? (
				<div className={styles.chart__loader}>
					<PulseLoader color="#fff" />
				</div>
			) : null}

			{/* show spinner while pair is loading */}
			{pairParams ? (
				<div
					id={containerId}
					className="chart-container"
					style={{ pointerEvents: quickOrderPanelDragging ? "none" : "auto" }}
				/>
			) : null}
			{pairParams && isQuickOrderPlacementOpen ? (
				<QuickOrderPanel onStartDrag={onStartDrag} onStopDrag={onStopDrag} />
			) : null}
			{hasError && (
				<div className={styles.chart__chartError}>
					<i className="ai ai-warning" />
					<span>
						It looks like something went wrong, please, reload the page or contact our support team.
					</span>
				</div>
			)}
		</div>
	)
}

export default observer(Chart)
