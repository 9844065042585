import React, { useEffect, useMemo } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import Tabs from "components/UI/Tabs"
import { useMst } from "models/Root"
import messages from "messages/finance"
import commonMessages from "messages/common"
import { AccountTypeEnum, EBybitWalletType } from "types/account"
import styles from "styles/pages/Wallets.module.scss"
import InternalLink from "components/InternalLink"
import visaMasterCardImg from "assets/images/common/visa_mastercard.svg"
import Tab from "components/UI/Tab"
import useParamQuery from "hooks/useSearchQuery"
import { queryVars } from "constants/query"
import { routes } from "constants/routing"

const PREFERRED_CURRENCIES = ["USD", "EUR"]
const PREFERRED_COIN = "BTC"

const WalletsTabs: React.FC = () => {
	const {
		global: { isAuthenticated },
		finance: { walletsFilter, setWalletsFilter },
		account: { profileStatus, getTypes, selectedType, loadBalances },
		buyCrypto: { rates, loadFiatRates },
		render,
	} = useMst()
	const { formatMessage } = useIntl()
	const navigate = useNavigate()
	const query = useParamQuery()

	useEffect(() => {
		// if (!rates.length) loadFiatRates({ direction: 1 })
	}, [])

	const buyCurrency = useMemo(() => {
		if (!rates.length) return null
		const fiatCurrencies = rates.map(({ fiat_currency }) => fiat_currency.code.toUpperCase())
		const preferredCurrency = PREFERRED_CURRENCIES.find(item =>
			fiatCurrencies.includes(item.toUpperCase()),
		)
		return preferredCurrency || fiatCurrencies[0]
	}, [rates.length])

	useEffect(() => {
		const type = query.get(queryVars.type) as AccountTypeEnum
		if (
			type &&
			[AccountTypeEnum.SPOT, AccountTypeEnum.CROSS, AccountTypeEnum.ISOLATED].includes(type)
		) {
			walletsFilter.setAccountType(type)
		}
	}, [])

	const handleTabChange = (name: string) => {
		if (name && name !== walletsFilter.accountType) {
			setWalletsFilter({
				...walletsFilter,
				accountType: name,
				sort:
					name === AccountTypeEnum.CROSS
						? "code.asc"
						: name === AccountTypeEnum.ISOLATED
						? "pair.asc"
						: "",
			})
			navigate({ [queryVars.search]: `?${queryVars.type}=${name}` })
		}
	}

	return (
		<Tabs className={styles.tabs}>
			{getTypes().map(({ name, label }) => (
				<Tab
					key={`wallet-tab-${name}`}
					name={name}
					isActive={selectedType === name}
					className={styles.tab}
					onClick={loadBalances}
					label={label}
				/>
			))}
			{!render.buyCrypto ||
			(isAuthenticated && profileStatus?.is_sub_account) ||
			!buyCurrency ? null : (
				<InternalLink
					className={styles.buy_crypto}
					to={routes.buyCrypto.getPair(`${buyCurrency}_${PREFERRED_COIN}`)}
					blank
				>
					<span>{formatMessage(commonMessages.buy_crypto)}</span>
					<img src={visaMasterCardImg} width="70" height="14" alt="Visa / MasterCard" />
					<i className="ai ai-chevron_right" />
				</InternalLink>
			)}
		</Tabs>
	)
}

export default observer(WalletsTabs)
