import React, { useCallback } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import { ITicker } from "models/Ticker"
import Star from "assets/icons/terminal/Star"

import DebouncedValue from "components/UI/DebouncedValue"

import styles from "../index.module.scss"

type TProps = {
	item: ITicker
	isFiat: boolean
	isMargin: boolean
	handleLinkClick?: (name: string) => void
	handleTickerFavoriteClick?: (symbol: string, isFavorite: boolean) => void
}

const Item: React.FC<TProps> = ({
	item,
	handleLinkClick,
	handleTickerFavoriteClick,
	isFiat,
	isMargin,
}) => {
	const { formatNumber } = useIntl()
	const fixedPercent = +(item.change_percent * 100).toFixed(2)
	const changePercent = fixedPercent < 0.01 && fixedPercent > -0.01 ? 0 : fixedPercent

	const onClickLink = useCallback(() => handleLinkClick?.(item.symbol), [item.symbol])

	const onClickFavorite = useCallback(() => {
		item.setIsFavorite(!item.is_favorite)
		handleTickerFavoriteClick?.(item.symbol, !item.is_favorite)
	}, [item.symbol, item.is_favorite])

	return (
		<li className={styles.search__coinsItem}>
			<button
				onClick={onClickFavorite}
				className={styles.search__favoriteButton}
				type="button"
				aria-label={`Toggle favorite ${item.base_coin} pair`}
			>
				<Star isChecked={item.is_favorite} />
			</button>

			<button onClick={onClickLink} type="button" className={styles.search__coinLink}>
				<div className={styles.search__coinName}>
					<span className={styles.search__coinNameText}>
						{/*{!isFiat ? item.base_currency_code : item.label}*/}
						{item.base_coin}
					</span>

					{/*{item.cross_margin_leverage > 0 && isMargin ? (*/}
					{/*	<span className={styles.search__coinNameIndicator}>{item.cross_margin_leverage}x</span>*/}
					{/*) : null}*/}
				</div>

				<span className={styles.search__coinPrice}>
					<DebouncedValue
						value={formatNumber(item.close, {
							minimumFractionDigits: item.price_precision ?? 0,
							maximumFractionDigits: item.price_precision ?? 8,
						})}
					/>
				</span>

				<span
					className={`${styles.search__coinPercent} ${
						changePercent > 0 ? styles.search__coinPercent___green : ""
					} ${changePercent < 0 ? styles.search__coinPercent___red : ""}`}
				>
					<DebouncedValue
						value={`${changePercent > 0 ? "+" : ""}${formatNumber(changePercent, {
							useGrouping: false,
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})} %`}
					/>
				</span>
			</button>
		</li>
	)
}

export default observer(Item)
