import { queryVars } from "constants/query"

export enum AccountTypeEnum {
	SPOT = "spot",
	CROSS = "cross",
	CROSS_MARGIN = "cross",
	ISOLATED = "isolated",
}

export enum WalletTypeEnum {
	SPOT = "SPOT",
	CROSS_MARGIN = "CROSS_MARGIN",
	ISOLATED_MARGIN = "ISOLATED_MARGIN",
}

export enum EBybitWalletType {
	FUND = "Funding Wallet",
	UNIFIED = "Unified Trading Wallet",
}

export interface IGetMarginStatusParams {
	[queryVars.wallet_type]: number
	[queryVars.pair]?: string
}

export type TGetFinanceAccountTypesResponse = string[]

export interface IGetFinanceBalancesParams {
	account_type: string
}

export type TGetFinanceBalancesResponse = {
	code: string
	balance: string
	reserve: string
	available: string
	converted: {
		USD: string
	}
}[]

export type TGetAggregatedBalanceResponse = {
	eqv: string
	balance: number
}[]

export interface IBalanceWS {
	code: string
	type: string
	market: string
	balance: string
	reserve: string
}

export enum RateAlgoPlanEnum {
	CONSTANT = "constant",
	DIRECT = "direct",
	DIV = "div",
	MUL = "mul",
	REVERSED = "reversed",
	MUL_REVERSED = "mulReversed",
}

export interface IPostMarginTransferParams {
	from_account_type: string
	to_account_type: string
	currency: string
	amount: string
}

export enum EMarginTransferStatus {
	SUCCESS = "SUCCESS",
}

export type TPostMarginTransferResponse = {
	from_account_type: string
	to_account_type: string
	currency: string
	amount: string
	status: EMarginTransferStatus
	transfer_id: string
}
