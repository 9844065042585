import useAccountType from "hooks/useAccountType"
import { useMst } from "models/Root"
import { AccountTypeEnum } from "types/account"

const useFunds = () => {
	const {
		global: { isAuthenticated },
		account: { balancesCrossFunds, balancesSpotFunds },
		history: { isTradeHistoryLoading, setFilterTypeTradeHistory, filterTypeTradeHistory },
		bybit: { pairAmountPrecision, pairPricePrecision, funds },
	} = useMst()
	const type = useAccountType()

	const listScheme =
		type === AccountTypeEnum.SPOT
			? [
					{
						arrCount: 1,
						id: 123,
						arrNames: [
							{
								headline: "Currency",
								width: 240,
								asociate: "currency",
							},
						],
						isScroll: false,
					},
					{
						arrCount: 11,
						id: 223,
						isScroll: true,
						arrNames: [
							{
								headline: "Available",
								width: 214,
								asociate: "available",
							},
							// {
							// 	headline: "Index Price",
							// 	width: 150,
							// 	asociate: "indexPrice",
							// },
							// {
							// 	headline: "Index Sum",
							// 	width: 200,
							// 	asociate: "indexSum",
							// },
						],
					},
			  ]
			: [
					{
						arrCount: 1,
						id: 123,
						arrNames: [
							{
								headline: "Currency",
								width: 240,
								asociate: "currency",
							},
						],
						isScroll: false,
					},
					{
						arrCount: 11,
						id: 223,
						isScroll: true,
						arrNames: [
							{
								headline: "Available",
								width: 214,
								asociate: "available",
							},
							{
								headline: "Total",
								width: 168,
								asociate: "total",
							},
							{
								headline: "Debt",
								width: 168,
								asociate: "loan",
								modalWidth: 168,
								modalContent: "Borrowed + Fees",
								isModal: true,
							},
							{
								headline: "Index Price",
								width: 150,
								asociate: "indexPrice",
							},
							{
								headline: "Index Sum",
								width: 200,
								asociate: "indexSum",
							},
						],
					},
			  ]

	const listHeadline = listScheme.flatMap(item => item.arrNames)

	return {
		listScheme,
		listHeadline,
		list: funds,
		isAuthenticated,
		pairAmountPrecision,
		pairPricePrecision,
		isTradeHistoryLoading,
		filterTypeTradeHistory,
		setFilterTypeTradeHistory,
	}
}

export default useFunds
