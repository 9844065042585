import ApiClient, { ApiBybitClient } from "helpers/ApiClient"
import {
	IBybitGetOrderbookQuery,
	TBybitOrderbook,
	IBybitGetRecentTradesQuery,
	TBybitGetRecentTradesResponse,
	IBybitGetTickersQuery,
	TBybitGetTickersResponse,
	IBybitGetInstrumentsInfoQuery,
	TBybitGetInstrumentsInfoResponse,
	IBybitGetChartDataQuery,
	TBybitChartData,
	TBybitWsCredentials,
} from "types/bybit"

const BybitService = {
	getOrderBook: (params: IBybitGetOrderbookQuery) =>
		ApiBybitClient.get<TBybitOrderbook>("v5/market/orderbook", params),

	getRecentTrades: (params: IBybitGetRecentTradesQuery) =>
		ApiBybitClient.get<TBybitGetRecentTradesResponse>("v5/market/recent-trade", params),

	getTickers: (params: IBybitGetTickersQuery) =>
		ApiBybitClient.get<TBybitGetTickersResponse>("v5/market/tickers", params),

	getInstrumentsInfo: (params: IBybitGetInstrumentsInfoQuery) =>
		ApiBybitClient.get<TBybitGetInstrumentsInfoResponse>("v5/market/instruments-info", params),

	getChartData: (params: IBybitGetChartDataQuery) =>
		ApiBybitClient.get<TBybitChartData>("v5/market/kline", params),

	getWsCredentials: (): Promise<TBybitWsCredentials> =>
		ApiClient.post("web/ws/credentials"),
}

export default BybitService
