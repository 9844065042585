import React from "react"
import { observer } from "mobx-react-lite"

import useTradingForm from "hooks/newUI/terminal/useTradingForm"
import { OrderTypeEnum } from "types/orders"
import Range from "components/NewUI/Range"
import { TradeActionsEnum } from "types/exchange"
import Header from "./Header"
import OrderQty from "./OrderQty"
import BuySellToggle from "./BuySellToggle"
import OrderType from "./OrderType"
import AvailableBalance from "./AvailableBalance"
import OrderPriceQty from "./OrderPriceQty"
import TriggerOrderPriceQty from "./TriggerOrderPriceQty"
import OrderValue from "./OrderValue"
import MaxBuyingAmount from "./MaxBuyingAmount"
import ActionButton from "./ActionButton"
import DepositTransfer from "./DepositTransfer"
import AuthenticateButtons from "./AuthenticateButtons"
import styles from "./index.module.scss"

const FunctionalPanel: React.FC = () => {
	const {
		orderPriceString,
		onChangeOrderPrice,
		onBlurOrderPrice,

		orderQtyString,
		onChangeOrderQty,
		onBlurOrderQty,

		orderValueString,
		onChangeOrderValue,
		onBlurOrderValue,

		rangePercentage,
		onChangeRangePercentage,

		triggerPriceString,

		tradeType,
		tradeAction,
		setTradeAction,
		setTradeType,

		setTradeMode,
		tradeMode,

		currencyCodeBySide,
		mainBalance,

		maxBuyingAmount,
		maxSellingAmount,
		getCurrencyCodeBySide,
		onChangeTargetPrice,
		isOnSubmitLoading,
		onSubmit,
		baseCurrencyCode,
		quoteCurrencyCode,
		isAuthenticated,

		recentTradeAction,
		bidTradeAction,
		askTradeAction,
		onBlurTargetPrice,

		orderPriceError,
		qtyError,
		quoteQtyError,
		stopPriceError,

		isLoaded,
	} = useTradingForm()

	return (
		<section className={styles.functionalPanel}>
			{/* A heading is required according to HTML standards for the section element. */}
			<h2 className="visually-hidden">Functional panel</h2>

			{/* Tabs */}
			<Header setTradeType={setTradeType} tradeType={tradeType} />

			{/* Margin select */}
			{/*{isMargin ? (*/}
			{/*	<MarginSelector*/}
			{/*		isLoaded={isLoaded}*/}
			{/*		setOpenMarginModal={setOpenMarginModal}*/}
			{/*		value={marginLvl}*/}
			{/*	/>*/}
			{/*) : null}*/}

			{/* Trade type buttons */}
			<BuySellToggle
				tradeType={tradeType}
				tradeAction={tradeAction}
				setTradeAction={setTradeAction}
			/>

			{/* Trade mode buttons */}
			<OrderType setTradeMode={setTradeMode} tradeMode={tradeMode} isLoaded={isLoaded} />

			{/* User balance */}
			<AvailableBalance currencyCodeBySide={currencyCodeBySide} mainBalance={mainBalance} />

			{/* Rendering of elements required for limit orders. */}
			{tradeMode === OrderTypeEnum.LIMIT ? (
				<>
					{/* Inputs for order price and qty. */}
					<OrderPriceQty
						price={orderPriceString}
						changeOrderPrice={onChangeOrderPrice}
						blurOrderPrice={onBlurOrderPrice}
						amount={orderQtyString}
						changeOrderQty={onChangeOrderQty}
						blurOrderQty={onBlurOrderQty}
						tradeAction={tradeAction}
						quoteCurrencyCode={quoteCurrencyCode}
						baseCurrencyCode={baseCurrencyCode}
						recentTradeAction={recentTradeAction}
						bidTradeAction={bidTradeAction}
						askTradeAction={askTradeAction}
						handleSubmit={onSubmit}
						orderPriceError={orderPriceError}
						qtyError={qtyError}
						isLoaded={isLoaded}
					/>

					<Range
						values={[rangePercentage]}
						setValues={onChangeRangePercentage}
						disabled={!isAuthenticated || !isLoaded}
					/>

					{/* Input for order value. */}
					<OrderValue
						quoteCurrencyCode={quoteCurrencyCode}
						tradeAction={tradeAction}
						total={orderValueString}
						changeOrderValue={onChangeOrderValue}
						blurOrderValue={onBlurOrderValue}
						handleSubmit={onSubmit}
						isLoaded={isLoaded}
					/>
				</>
			) : null}

			{/* Rendering of elements required for market orders. */}
			{tradeMode === OrderTypeEnum.MARKET ? (
				<>
					{tradeAction === TradeActionsEnum.BUY ? (
						<>
							{/* Input for order value. */}
							<OrderValue
								quoteCurrencyCode={quoteCurrencyCode}
								tradeAction={tradeAction}
								total={orderValueString}
								changeOrderValue={onChangeOrderValue}
								blurOrderValue={onBlurOrderValue}
								handleSubmit={onSubmit}
								quoteQtyError={quoteQtyError}
								isLoaded={isLoaded}
							/>
						</>
					) : (
						<>
							{/* Input for order Qty. */}
							<OrderQty
								tradeAction={tradeAction}
								amount={orderQtyString}
								changeOrderQty={onChangeOrderQty}
								blurOrderQty={onBlurOrderQty}
								baseCurrencyCode={baseCurrencyCode}
								handleSubmit={onSubmit}
								qtyError={qtyError}
								isLoaded={isLoaded}
							/>
						</>
					)}

					<Range
						values={[rangePercentage]}
						setValues={onChangeRangePercentage}
						disabled={!isAuthenticated || !isLoaded}
					/>
				</>
			) : null}

			{/* Rendering of elements required for stop-order orders. */}
			{tradeMode === OrderTypeEnum.STOP_ORDER ? (
				<>
					{/* Inputs for trigger price, order price and qty. */}
					<TriggerOrderPriceQty
						tradeAction={tradeAction}
						stop_price={triggerPriceString}
						onChangeTargetPrice={onChangeTargetPrice}
						onBlurTargetPrice={onBlurTargetPrice}
						onBlurPrice={onBlurOrderPrice}
						onBlurAmount={onBlurOrderQty}
						onChangePrice={onChangeOrderPrice}
						onChangeAmount={onChangeOrderQty}
						orderPriceError={orderPriceError}
						stopPriceError={stopPriceError}
						qtyError={qtyError}
						quoteCurrencyCode={quoteCurrencyCode}
						price={orderPriceString}
						baseCurrencyCode={baseCurrencyCode}
						amount={orderQtyString}
						handleSubmit={onSubmit}
						isLoaded={isLoaded}
					/>

					<Range
						values={[rangePercentage]}
						setValues={onChangeRangePercentage}
						disabled={!isAuthenticated || !isLoaded}
					/>

					{/* Input for order value. */}
					<OrderValue
						quoteCurrencyCode={quoteCurrencyCode}
						tradeAction={tradeAction}
						total={orderValueString}
						changeOrderValue={onChangeOrderValue}
						blurOrderValue={onBlurOrderValue}
						handleSubmit={onSubmit}
						isLoaded={isLoaded}
					/>
				</>
			) : null}

			{isAuthenticated ? (
				// Rendering of elements required for authenticated users
				<>
					{/* Maximum buying amount line. */}
					<MaxBuyingAmount
						tradeAction={tradeAction}
						maxSellingAmount={maxSellingAmount}
						maxBuyingAmount={maxBuyingAmount}
						getCurrencyCodeBySide={getCurrencyCodeBySide}
					/>

					{/* Buy/Sell button. */}
					<ActionButton
						baseCurrencyCode={baseCurrencyCode}
						tradeAction={tradeAction}
						tradeType={tradeType}
						handleSubmit={onSubmit}
						isOnSubmitLoading={isOnSubmitLoading}
						isLoaded={isLoaded}
					/>

					{/* Buttons for deposit and transfer. */}
					<DepositTransfer />
				</>
			) : (
				// Rendering of elements required for unauthenticated users
				<AuthenticateButtons />
			)}
		</section>
	)
}

export default observer(FunctionalPanel)
