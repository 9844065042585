import React, { FC, useCallback } from "react"
import { observer } from "mobx-react-lite"

import NavArrowRight from "assets/icons/header/NavArrowRight"
import ExternalImage from "components/NewUI/ExternalImage"
import styles from "components/NewUI/Header/Navigation/index.module.scss"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Item: FC<{
	item: { label: string; baseCoin: string; symbol: string }
	handleLinkClick: (name: string) => void
	isRenderMargin?: boolean
}> = ({ item, handleLinkClick, isRenderMargin }) => {
	const onClick = useCallback(() => handleLinkClick(item.symbol), [item.symbol])

	return (
		<li className={styles.nav__tradingCoinsItem}>
			<button type="button" onClick={onClick} className={styles.nav__tradingCoinWrapper}>
				{/*<ExternalImage*/}
				{/*	className={styles.nav__tradingCoinImage}*/}
				{/*	src={`/media/icons/${item.baseCoin.toLowerCase()}.svg`}*/}
				{/*	alt={item.symbol}*/}
				{/*	fallback={*/}
				{/*		<svg*/}
				{/*			xmlns="http://www.w3.org/2000/svg"*/}
				{/*			width="40"*/}
				{/*			height="40"*/}
				{/*			viewBox="0 0 40 40"*/}
				{/*			fill="none"*/}
				{/*			className={styles.nav__tradingCoinCircle}*/}
				{/*		>*/}
				{/*			<circle cx="20" cy="20" r="19.5" stroke="#7c59ff" strokeDasharray="8 8" />*/}
				{/*		</svg>*/}
				{/*	}*/}
				{/*/>*/}

				<div className={styles.nav__tradingCoinContent}>
					<span className={styles.nav__tradingCoinPair}>{item.symbol}</span>

					<div className={styles.nav__tradingCoinName}>
						<span className={styles.nav__tradingCoinNameText}>{item.baseCoin}</span>

						{/*{item.cross_margin_leverage && item.cross_margin_leverage !== 0 && isRenderMargin ? (*/}
						{/*	<span className={styles.nav__tradingCoinMarginText}>*/}
						{/*		{item.cross_margin_leverage}x*/}
						{/*	</span>*/}
						{/*) : null}*/}
					</div>
				</div>

				<NavArrowRight />
			</button>
		</li>
	)
}

export default observer(Item)
