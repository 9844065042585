import { useEffect, useRef, useState } from "react"
import { cast } from "mobx-state-tree"

import {
	EBybitMessageTopic,
	EBybitMessageType,
	TBybitOrderbook,
	TBybitWebSocketTrade,
} from "types/bybit"
import BybitWebSocket, { TBybitWebSocketListeners } from "helpers/bybitWebSocket"
import { useMst } from "models/Root"

interface UseBybitWebSocketProps {
	baseUrl: string
	apiKey?: string
	expires?: number
	signature?: string
	channels: string[]
	disabled?: boolean
}

const useBybitWebSocket = ({
	baseUrl,
	apiKey,
	expires,
	signature,
	channels,
	disabled,
}: UseBybitWebSocketProps) => {
	const wsRef = useRef<BybitWebSocket | null>(null)
	const [klineData, setKlineData] = useState<any | null>(null)
	const {
		terminal,
		bybit: { setTicker, patchRecentTrades, currentPair },
		tickers: { updateTickers },
		history: { updateOrder },
	} = useMst()

	useEffect(() => {
		if (disabled) return undefined

		const bybitWs = new BybitWebSocket({ baseUrl, apiKey, expires, signature })

		const onMessage: TBybitWebSocketListeners["message"] = (message: {
			topic: string
			type: EBybitMessageType
			data: any
		}) => {
			if (message.topic?.startsWith(EBybitMessageTopic.ORDERBOOK)) {
				const { b: bids, a: asks } = message.data as TBybitOrderbook
				if (message.type === EBybitMessageType.SNAPSHOT) {
					terminal.updateOrderBook(bids, asks)
				} else if (message.type === EBybitMessageType.DELTA) {
					terminal.patchOrderBook(bids, asks)
				}
			} else if (message.topic?.startsWith(EBybitMessageTopic.TICKERS)) {
				if (message.type === EBybitMessageType.SNAPSHOT) {
					setTicker(message.data)
					updateTickers(message.data)
				}
			} else if (message.topic?.startsWith(EBybitMessageTopic.PUBLIC_TRADE)) {
				const trades = message.data as TBybitWebSocketTrade[]
				patchRecentTrades(trades)
			} else if (message.topic?.startsWith(EBybitMessageTopic.KLINE)) {
				setKlineData(message.data[0])
			} else if (message.topic?.startsWith(EBybitMessageTopic.ORDER)) {
				updateOrder(
					cast({
						symbol: currentPair.symbol,
						status: currentPair.status,
						baseCoin: currentPair.baseCoin,
						quoteCoin: currentPair.quoteCoin,
					}),
					message.data,
				)
			}
		}

		bybitWs
			.connect()
			.then(() => {
				bybitWs.startPingInterval()
				if (apiKey && expires && signature) {
					bybitWs.authenticate().then(result => {
						if (result === true) bybitWs.subscribe(...channels)
						else throw new Error(result)
					})
				} else {
					bybitWs.subscribe(...channels)
				}
				bybitWs.on("message", onMessage)
			})
			.catch(error => console.error("Failed to connect:", error))

		wsRef.current = bybitWs

		return () => {
			bybitWs.off("message", onMessage)
			wsRef.current?.close()
		}
	}, [baseUrl, apiKey, expires, signature, channels.join()])

	return { bybitSocket: wsRef.current, klineData }
}

export default useBybitWebSocket
